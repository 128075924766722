import { IRecipesModuleState } from ".";
import { RecipesModuleAction_select, RecipesModuleAction_set } from "./actions";

export function selectMutation(
  state: IRecipesModuleState,
  action: RecipesModuleAction_select
) {
  const url = new URL(window.location.href);
  url.searchParams.set("id", action.recipe.id);
  window.history.pushState(null, "Kochbuch", url.toString());
  state.selectedRecipe = action.recipe.id;
}

export function setMutation(
  state: IRecipesModuleState,
  action: RecipesModuleAction_set
) {
  const recipeIds = state.recipes.map((r) => r.id);
  const recipes = state.recipes;
  action.recipes.forEach((recipe) => {
    if (recipeIds.includes(recipe.id)) {
      const index = state.recipes.findIndex((r) => r.id === recipe.id);
      recipes[index] = recipe;
    } else {
      recipes.push(recipe);
    }
  });
  state.recipes = recipes.slice();
}
