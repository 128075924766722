import React from "react";
import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";

export class Header extends React.Component<
  {
    login: (event: any) => void;
    logout: () => void;
    user: string;
  },
  {}
> {
  render() {
    const isLoggedIn = this.props.user.length > 0;
    return (
      <Navbar bg="primary" expand="lg">
        <Navbar.Brand>Kochbuch</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {isLoggedIn && <Nav.Link>Willkommen {this.props.user}</Nav.Link>}
            {/* <Nav.Link href="#link">Link</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>  */}
          </Nav>
          <Form
            inline
            onSubmit={(e: any) => {
              e.preventDefault();
              this.props.login(e);
            }}
          >
            <FormControl
              style={{ marginBottom: "5px" }}
              type="text"
              placeholder="Username"
              className="mr-sm-2"
              name="username"
            />
            <FormControl
              style={{ marginBottom: "5px" }}
              type="password"
              placeholder="Password"
              className="mr-sm-2"
              name="password"
            />
            <Button
              variant="success"
              type="submit"
              style={{
                width: "120px",
                marginRight: "10px",
                marginBottom: "5px"
              }}
            >
              Login
            </Button>
          </Form>
          {isLoggedIn && (
            <Button
              variant="danger"
              onClick={this.props.logout}
              style={{ width: "120px", marginBottom: "5px" }}
            >
              Log out
            </Button>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
