import React from "react";
import { connectStore } from "redux-box";
import { userModule, IUserModule } from "../../redux/userModule";
import { RecipeViewer } from "../RecipeViewer";
import { Header } from "../header";
import { Welcome } from "./Welcome";

@connectStore({
  userModule: userModule
})
export class Application extends React.Component<
  {
    userModule?: IUserModule;
  },
  {}
> {
  //DevLogin
  componentDidMount() {
    // const { login } = this.props.userModule!;
    // login("admin", "ekna91<3");
  }
  login = (event: any) => {
    const { login } = this.props.userModule!;
    const formData = new FormData(event.target);
    if (formData.get("username") && formData.get("password")) {
      login(
        formData.get("username") as string,
        formData.get("password") as string
      );
    }
  };
  render() {
    const { logout, username } = this.props.userModule!;
    const isLoggedIn = username.length > 0;
    const renderBody = isLoggedIn ? <RecipeViewer /> : <Welcome />;
    return (
      <div>
        <Header login={this.login} logout={logout} user={username}></Header>
        {renderBody}
      </div>
    );
  }
}
