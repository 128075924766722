import React from "react";
import { Recipe } from "../../common/Recipes";
import { DetailViewer } from "../RecipeViewer/DetailViewer";
import { baseUrl } from "../..";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

const axios = require("axios").default;

export class Welcome extends React.Component<
  {},
  {
    recipe: Recipe;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { recipe: null };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const id = url.searchParams.get("id");
    if (id) {
      const toastId = toast.warn("Rezept laden ...", {
        autoClose: false,
      });
      axios
        .get(baseUrl + "/recipe/?id=" + id, { withCredentials: true })
        .then((response: any) => {
          return response.data;
        })
        .then((r: Recipe) => this.setState({ recipe: r }))
        .catch((err: any) => {
          console.log("Loading Error:" + err);
          toast.error("Laden fehlgeschlagen!");
        })
        .finally(() => {
          toast.dismiss(toastId);
        });
    }
  }

  body = () => {
    if (this.state.recipe)
      return (
        <Container style={{ padding: "10px", textAlign: "left" }}>
          <Row>
            <Col>
              <DetailViewer recipe={this.state.recipe} />
            </Col>
          </Row>
        </Container>
      );
    else
      return (
        <>
          <h1>Kochbuch</h1>
          <h2>Bitte einloggen!</h2>
        </>
      );
  };
  render() {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>{this.body()}</div>
    );
  }
}
