import React from "react";
import { Recipe, CourseEnum } from "../../common/Recipes";
import { ListGroup, Form, ButtonToolbar, Button, Col } from "react-bootstrap";
import { Collapse } from "react-collapse";
import { mobileCheck } from "./utils";
import CreatableSelect from "react-select/creatable";

export class RecipeSelector extends React.Component<
  {
    recipes: Recipe[];
    selectedRecipe: string;
    onSelectRecipe: (recipe: Recipe) => void;
    options: { value: string; label: string }[];
  },
  {
    filteredRecipeIds: string[];
    isListOpen: boolean;
    course: CourseEnum | "ALL";
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { filteredRecipeIds: [], isListOpen: true, course: "ALL" };
  }

  getItems = () => {
    const { recipes, selectedRecipe } = this.props;
    return recipes
      .filter(
        (recipe) =>
          (this.state.filteredRecipeIds.length === 0 ||
            this.state.filteredRecipeIds.includes(recipe.id)) &&
          (this.state.course === "ALL" || this.state.course === recipe.course)
      )
      .sort((r1, r2) => r1.name.localeCompare(r2.name))
      .map((recipe) => {
        const isActive: boolean = recipe.id === selectedRecipe;
        return (
          <ListGroup.Item
            active={isActive}
            action
            onClick={() => {
              this.props.onSelectRecipe(new Recipe(recipe));
              if (mobileCheck())
                this.setState({ ...this.state, isListOpen: false });
            }}
            key={"list" + recipe.id}
          >
            {recipe.name}
          </ListGroup.Item>
        );
      });
  };

  onChange = (e: any) => {
    const { recipes } = this.props;
    const selectableIds = recipes
      .filter(
        (r) =>
          getSearchString(r).includes(e.target.value.toLowerCase()) &&
          (this.state.course === "ALL" || this.state.course === r.course)
      )
      .map((r) => r.id);
    this.setState({ ...this.state, filteredRecipeIds: selectableIds });
  };

  changeCourse = (e: any) => {
    const newCourse = e.target.value;
    const { recipes } = this.props;
    const selectableIds = recipes
      .filter((r) => newCourse === "ALL" || newCourse === r.course)
      .map((r) => r.id);
    this.setState({
      ...this.state,
      filteredRecipeIds: selectableIds,
      course: newCourse,
    });
  };

  search = (value: any) => {
    const { recipes } = this.props;
    const selectableIds = recipes
      .filter(
        (r) =>
          recipeFilter(r, value) &&
          (this.state.course === "ALL" || this.state.course === r.course)
      )
      .map((r) => r.id);
    this.setState({ ...this.state, filteredRecipeIds: selectableIds });
  };

  render() {
    const items = this.getItems();
    return (
      <>
        <Col style={{ minWidth: "300px", maxWidth: "685px" }}>
          <h4>Rezept auswählen:</h4>
          <p>
            <ButtonToolbar>
              <Button
                className="toolbar"
                variant={this.state.course === "ALL" ? "primary" : "secondary"}
                onClick={this.changeCourse}
                active={this.state.course === "ALL"}
                value={"ALL"}
              >
                Alle
              </Button>
              <Button
                className="toolbar"
                variant={
                  this.state.course === CourseEnum.APPETIZER
                    ? "primary"
                    : "secondary"
                }
                onClick={this.changeCourse}
                active={this.state.course === CourseEnum.APPETIZER}
                value={CourseEnum.APPETIZER}
              >
                Vorspeise
              </Button>
              <Button
                className="toolbar"
                variant={
                  this.state.course === CourseEnum.MAIN
                    ? "primary"
                    : "secondary"
                }
                onClick={this.changeCourse}
                active={this.state.course === CourseEnum.MAIN}
                value={CourseEnum.MAIN}
              >
                Hauptgericht
              </Button>
              <Button
                className="toolbar"
                variant={
                  this.state.course === CourseEnum.DESSERT
                    ? "primary"
                    : "secondary"
                }
                onClick={this.changeCourse}
                active={this.state.course === CourseEnum.DESSERT}
                value={CourseEnum.DESSERT}
              >
                Nachspeise
              </Button>
              <Button
                className="toolbar"
                variant={
                  this.state.course === CourseEnum.PASTRY
                    ? "primary"
                    : "secondary"
                }
                onClick={this.changeCourse}
                active={this.state.course === CourseEnum.PASTRY}
                value={CourseEnum.PASTRY}
              >
                Gebäck
              </Button>
            </ButtonToolbar>
          </p>
          <div style={{ marginBottom: "5px" }}>
            <Form.Control
              type="text"
              onChange={this.onChange}
              placeholder="Freitextsuche"
            ></Form.Control>
          </div>
          <div>
            <CreatableSelect
              styles={{
                container: () => ({ width: "100%" }),
                menu: (base) => ({ ...base, position: "relative" }),
              }}
              isMulti
              onChange={this.search}
              options={this.props.options}
              placeholder="Durchsuche Tags"
            />
          </div>
          {this.state.isListOpen ? (
            <span
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={() =>
                this.setState({ ...this.state, isListOpen: false })
              }
            >
              Liste einklappen
            </span>
          ) : (
            <span
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={() => this.setState({ ...this.state, isListOpen: true })}
            >
              Liste ausklappen
            </span>
          )}
          <span
            style={{
              float: "right",
            }}
          >
            Suchergebnisse:&nbsp;{this.state.filteredRecipeIds?.length || 0}
          </span>
        </Col>
        <Col style={{ minWidth: "300px" }}>
          <Collapse isOpened={this.state.isListOpen}>
            <div>
              <ListGroup style={{ marginTop: "10px", marginBottom: "10px" }}>
                {items}
              </ListGroup>
            </div>
          </Collapse>
        </Col>
      </>
    );
  }
}
function recipeFilter(r: Recipe, value: any): boolean {
  if (!value || value.length === 0) return true;
  const searchterms: string[] = value.map((v: any) =>
    String(v.value).toLowerCase()
  );
  let isApplicable = true;
  searchterms.forEach((searchterm) => {
    if (
      !r.tags
        .map((t) => t.toLowerCase())
        .join("")
        .includes(searchterm)
    )
      isApplicable = false;
  });
  return isApplicable;
}

function getSearchString(recipe: Recipe) {
  let searchstring = "";
  if (recipe.name) searchstring = searchstring + recipe.name.toLowerCase();
  if (recipe.instructions)
    searchstring = searchstring + recipe.instructions.toLowerCase();
  if (recipe.ingredients)
    searchstring =
      searchstring +
      recipe.ingredients.map((i) => i.name.toLowerCase()).join("");
  if (recipe.tags)
    searchstring =
      searchstring + recipe.tags.map((t) => t.toLowerCase()).join("");
  return searchstring;
}
