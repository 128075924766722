import React from "react";
import { connectStore } from "redux-box";
import { recipesModule, IRecipesModule } from "../../redux/recipesModule";
import { RecipeSelector } from "./RecipeSelector";
import { DetailViewer } from "./DetailViewer";
import { Container, Row, Col } from "react-bootstrap";
import { Recipe } from "../../common/Recipes";
import { userModule, IUserModule } from "../../redux/userModule";
import { Controls } from "./Controls";
import { DetailEditor } from "./DetailEditor";

@connectStore({
  recipesModule: recipesModule,
  userModule: userModule,
})
export class RecipeViewer extends React.Component<
  {
    recipesModule?: IRecipesModule;
    userModule?: IUserModule;
  },
  {
    isEditor: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { isEditor: false };
  }

  private getOptions() {
    const { recipesModule } = this.props;
    const tags: string[] = new Array();
    recipesModule!.recipes.forEach((r) => {
      if (r.tags)
        r.tags.forEach((t) => {
          if (!tags.includes(t)) tags.push(t);
        });
    });
    tags.sort();
    const options = tags.map((t) => ({
      label: t,
      value: t,
    }));
    return options;
  }

  onSelectRecipe = (recipe: Recipe) => {
    this.props.recipesModule!.select(recipe);
  };
  onSave = (recipe: Recipe) => {
    this.setState({ isEditor: false });
    const { recipesModule } = this.props;
    recipesModule!.edit(recipe);
  };
  renderDetails = (selectedRecipe: Recipe | undefined) => {
    if (!selectedRecipe) return <Col></Col>;
    const { recipesModule, userModule } = this.props;
    const isGuest = userModule && userModule.username === "gast";
    return (
      <Col style={{ margin: "10px" }}>
        {!isGuest && (
          <Row style={{ justifyContent: "end" }}>
            <Controls
              edit={() => this.setState({ isEditor: !this.state.isEditor })}
              new={recipesModule!.new}
            />
          </Row>
        )}

        <Row>
          {this.state.isEditor ? (
            <DetailEditor
              save={this.onSave}
              user={this.props.userModule!.username}
              recipe={selectedRecipe}
              options={this.getOptions()}
            />
          ) : (
            <DetailViewer recipe={selectedRecipe} />
          )}
        </Row>
      </Col>
    );
  };
  render() {
    const { recipesModule } = this.props;
    const selectedRecipe: Recipe | undefined = recipesModule!.recipes.find(
      (r: any) => r.id === recipesModule!.selectedRecipe
    );
    const details = this.renderDetails(selectedRecipe);
    const options = this.getOptions();
    return (
      <Container style={{ padding: "10px" }} fluid>
        <Row>
          <RecipeSelector
            recipes={recipesModule!.recipes}
            selectedRecipe={recipesModule!.selectedRecipe}
            onSelectRecipe={this.onSelectRecipe}
            options={this.getOptions()}
          />
          {details}
        </Row>
      </Container>
    );
  }
}
