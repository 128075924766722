import { Action } from "redux";
import { call, put } from "redux-saga/effects";
import {
  RecipesModuleAction_set,
  RecipesModuleAction_edit,
  RecipesModuleAction_select,
} from "./actions";
import { RecipesModuleActionsEnum } from ".";
import { baseUrl } from "../..";
import { Recipe } from "../../common/Recipes";
import { toast } from "react-toastify";

const axios = require("axios").default;

export function* getSaga(action: Action) {
  const recipes = yield call(() =>
    axios
      .get(baseUrl + "/recipes", { withCredentials: true })
      .then((response: any) => {
        return response.data;
      })
      .catch(() => toast.error("Error saving data"))
  );
  yield put({
    type: RecipesModuleActionsEnum.RECIPES_SET,
    recipes: recipes.map((recipe: Recipe) => new Recipe(recipe)),
  } as RecipesModuleAction_set);
}
export function* newSaga(action: Action) {
  try {
    const recipe = yield call(() =>
      axios
        .get(baseUrl + "/edit/new", { withCredentials: true })
        .then((response: any) => {
          return response.data;
        })
        .catch(() => toast.error("Error saving data"))
    );
    yield put({
      type: RecipesModuleActionsEnum.RECIPES_SET,
      recipes: [recipe],
    } as RecipesModuleAction_set);
    yield put({
      type: RecipesModuleActionsEnum.RECIPES_SELECT,
      recipe: recipe,
    } as RecipesModuleAction_select);
  } catch (err) {
    console.log(err);
  }
}
export function* editSaga(action: RecipesModuleAction_edit) {
  try {
    const recipe = yield call(() =>
      axios
        .post(baseUrl + "/edit/change", action.recipe, {
          withCredentials: true,
        })
        .then((response: any) => {
          return response.data;
        })
        .catch(() => {
          toast.error("Error saving data");
        })
    );
    yield put({
      type: RecipesModuleActionsEnum.RECIPES_SET,
      recipes: [recipe],
    } as RecipesModuleAction_set);
    yield put({
      type: RecipesModuleActionsEnum.RECIPES_SELECT,
      recipe: recipe,
    } as RecipesModuleAction_select);
  } catch (err) {
    console.log(err);
  }
}
