import React from "react";
import { Recipe, CourseEnum } from "../../common/Recipes";
import { Table } from "react-bootstrap";
import Select from "react-select";

export class DetailViewer extends React.Component<
  {
    recipe: Recipe | undefined;
  },
  {}
> {
  getIngredients = () => {
    const { recipe } = this.props;
    return recipe!.ingredients.map((ingredient, index) => {
      return (
        <tr key={"row" + index}>
          <td key={"quantity" + index}>{ingredient.quantity}</td>
          <td key={"ingredient" + index}>{ingredient.unit}</td>
          <td key={"name" + index}>{ingredient.name}</td>
        </tr>
      );
    });
  };
  getCourse = () => {
    const { recipe } = this.props;
    let course = "";
    switch (recipe.course) {
      case CourseEnum.APPETIZER:
        course = "Vorspeise";
        break;
      case CourseEnum.DESSERT:
        course = "Nachspeise";
        break;
      case CourseEnum.MAIN:
        course = "Hauptgericht";
        break;
      case CourseEnum.PASTRY:
        course = "Gebäck";
        break;
    }
    return <p>({course})</p>;
  };
  private getTags() {
    const { recipe } = this.props;
    if (!recipe?.tags) return [];
    else return recipe.tags.map((t) => ({ value: t, label: t }));
  }
  render() {
    const { recipe } = this.props;
    if (!recipe) return null;
    const ingredients = this.getIngredients();
    const course = this.getCourse();
    const tags = this.getTags();
    return (
      <div>
        <h4>{recipe.name}</h4>
        <p>von {recipe.author}</p>
        {course}
        <Select
          styles={{
            container: () => ({ width: "100%" }),
            multiValueRemove: (base, _state) => ({ ...base, display: "none" }),
            multiValueLabel: (base, state) => ({
              ...base,
              backgroundColor: "gray",
              fontWeight: "bold",
              color: "white",
              paddingRight: 6,
            }),
          }}
          isMulti
          value={tags}
          isDisabled
          isClearable={false}
          isOptionDisabled={() => false}
          placeholder="keine"
        />
        <br />
        <Table striped bordered size="sm">
          <tbody>{ingredients}</tbody>
        </Table>
        <p style={{ whiteSpace: "break-spaces" }}>{recipe.instructions}</p>
      </div>
    );
  }
}
