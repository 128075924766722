// store/user.js
import { createSagas } from "redux-box";
import { Recipe } from "../../common/Recipes";
import {
  RecipesModuleAction_select,
  RecipesModuleAction_set,
  RecipesModuleAction_edit
} from "./actions";
import { selectMutation, setMutation } from "./mutations";
import { Action } from "redux";
import { getSaga, newSaga, editSaga } from "./sagas";

export enum RecipesModuleActionsEnum {
  RECIPES_SELECT = "RECIPES_SELECT",
  RECIPES_GET = "RECIPES_GET",
  RECIPES_SET = "RECIPES_SET",
  RECIPES_NEW = "RECIPES_NEW",
  RECIPES_EDIT = "RECIPES_EDIT"
}
export interface IRecipesModuleState {
  recipes: Recipe[];
  selectedRecipe: string;
}
export interface IRecipesModuleActions {
  select: (recipe: Recipe) => RecipesModuleAction_select;
  get: () => Action;
  set: (recipes: Recipe[]) => RecipesModuleAction_set;
  new: () => Action;
  edit: (recipe: Recipe) => RecipesModuleAction_edit;
}
export interface IRecipesModule
  extends IRecipesModuleState,
    IRecipesModuleActions {}

// define initial state
const state: IRecipesModuleState = {
  recipes: [],
  selectedRecipe: ""
};

// define dispatchers
export const actions: IRecipesModuleActions = {
  select: recipe => ({
    type: RecipesModuleActionsEnum.RECIPES_SELECT,
    recipe: recipe
  }),
  get: () => ({ type: RecipesModuleActionsEnum.RECIPES_GET }),
  set: recipes => ({
    type: RecipesModuleActionsEnum.RECIPES_SET,
    recipes: recipes
  }),
  new: () => ({ type: RecipesModuleActionsEnum.RECIPES_NEW }),
  edit: recipe => ({
    type: RecipesModuleActionsEnum.RECIPES_EDIT,
    recipe: recipe
  })
};

// define mutations
const mutations = {
  [RecipesModuleActionsEnum.RECIPES_SELECT]: selectMutation,
  [RecipesModuleActionsEnum.RECIPES_SET]: setMutation
};

// define sagas
const sagas = createSagas({
  [RecipesModuleActionsEnum.RECIPES_GET]: getSaga,
  [RecipesModuleActionsEnum.RECIPES_NEW]: newSaga,
  [RecipesModuleActionsEnum.RECIPES_EDIT]: editSaga
});

export const recipesModule = {
  name: "recipesModule",
  actions,
  state,
  mutations,
  sagas
};
