import React from "react";
import store from "./redux/store";
import { Provider } from "react-redux";
import { Application } from "./components/Application";
import { ToastContainer } from "react-toastify";

export default function App() {
  return (
    <div>
      <Provider store={store}>
        <Application />
      </Provider>
      <ToastContainer position="bottom-right"></ToastContainer>
    </div>
  );
}
