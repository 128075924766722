import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";

export class Controls extends React.Component<
  {
    edit: () => void;
    new: () => void;
  },
  {}
> {
  render() {
    return (
      <div>
        <ButtonGroup>
          <Button variant="primary" onClick={this.props.edit}>
            Bearbeiten
          </Button>
          <Button variant="success" onClick={this.props.new}>
            Neu
          </Button>
        </ButtonGroup>
      </div>
    );
  }
}
