// store/user.js
import { createSagas } from "redux-box";
import { Action } from "redux";
import { loginSaga, logoutSaga } from "./sagas";
import { UserModuleAction_setName, UserModuleAction_login } from "./actions";

export enum UserModuleActionsEnum {
  USER_SET_NAME = "USER_SET_NAME",
  USER_LOGIN = "USER_LOGIN",
  USER_LOGOUT = "USER_LOGOUT"
}

export interface IUserModuleState {
  username: string;
}

export interface IUserModuleActions {
  setName: (name: string) => UserModuleAction_setName;
  login: (username: string, password: string) => UserModuleAction_login;
  logout: () => Action;
}

export interface IUserModule extends IUserModuleState, IUserModuleActions {}
// define initial state
const state: IUserModuleState = {
  username: ""
};

// define dispatchers
export const actions: IUserModuleActions = {
  setName: name => ({
    type: UserModuleActionsEnum.USER_SET_NAME,
    username: name
  }),
  login: (username, password) => ({
    type: UserModuleActionsEnum.USER_LOGIN,
    username: username,
    password: password
  }),
  logout: () => ({
    type: UserModuleActionsEnum.USER_LOGOUT
  })
};

// define mutations
const mutations = {
  [UserModuleActionsEnum.USER_SET_NAME]: (
    state: IUserModuleState,
    action: UserModuleAction_setName
  ) => (state.username = action.username)
};

// define sagas
const sagas = createSagas({
  [UserModuleActionsEnum.USER_LOGIN]: loginSaga,
  [UserModuleActionsEnum.USER_LOGOUT]: logoutSaga
});

export const userModule = {
  name: "userModule",
  actions,
  state,
  mutations,
  sagas
};
