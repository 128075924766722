import { call, put } from "redux-saga/effects";
import { UserModuleAction_login, UserModuleAction_setName } from "./actions";
import { Action } from "redux";
import { UserModuleActionsEnum } from ".";
import { RecipesModuleActionsEnum } from "../recipesModule";
import { baseUrl } from "../..";
import { toast } from "react-toastify";
const axios = require("axios").default;

export function* loginSaga(action: UserModuleAction_login) {
  const toastId = toast.warn("Einloggen als " + action.username + " ...", {
    autoClose: false,
  });
  try {
    yield call(() => {
      return axios.post(
        baseUrl +
          "/login?username=" +
          action.username +
          "&password=" +
          action.password,
        null,
        {
          withCredentials: true,
        }
      );
    });
    yield put({
      type: UserModuleActionsEnum.USER_SET_NAME,
      username: action.username,
    } as UserModuleAction_setName);
    yield put({
      type: RecipesModuleActionsEnum.RECIPES_GET,
    });
    toast.success("Login erfolgreich!");
  } catch (err) {
    console.log("Login Fehler:" + err);
    toast.error("Login fehlgeschlagen!");
  } finally {
    toast.dismiss(toastId);
  }
}
export function* logoutSaga(action: Action) {
  const toastId = toast.warn("Ausloggen", {
    autoClose: false,
  });
  try {
    yield call(() => {
      return axios.post(baseUrl + "/logout", null, {
        withCredentials: true,
      });
    });
  } catch (err) {
    console.log("Log out", err);
  } finally {
    toast.dismiss(toastId);
  }
  yield put({
    type: UserModuleActionsEnum.USER_SET_NAME,
    username: "",
  } as UserModuleAction_setName);
}
