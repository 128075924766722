import "./resources/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

export const baseUrl = "https://kochbuch-api.klusmann.info";
// export const baseUrl = "http://localhost:8080";
ReactDOM.render(<App />, document.getElementById("root"));
