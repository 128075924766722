import React from "react";
import { Recipe, CourseEnum } from "../../common/Recipes";
import { Form, Button, Col, Row, Table, ButtonGroup } from "react-bootstrap";
import { Ingredient } from "../../common/Ingredient";
import CreatableSelect from "react-select/creatable";
import { ActionMeta } from "react-select";

export class DetailEditor extends React.Component<
  {
    save: (recipe: Recipe) => void;
    user: string;
    recipe: Recipe;
    options: { value: string; label: string }[];
  },
  {
    recipe: Recipe;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { recipe: null };
  }
  handleTagging = (value: any, _action: ActionMeta<any>) => {
    let tags: string[] = [];
    if (value) {
      tags = value.map((v: any) => v.value);
    }
    const recipe = this.state.recipe;
    this.setState({
      ...this.state,
      recipe: {
        id: recipe.id,
        author: recipe.author,
        name: recipe.name,
        instructions: recipe.instructions,
        course: recipe.course,
        ingredients: recipe.ingredients,
        tags: tags,
      },
    });
  };

  getIngredients = () => {
    const recipe = this.state.recipe;
    if (!recipe) return null;
    return recipe.ingredients.map((ingredient) => {
      return (
        <tr key={"row" + ingredient.name}>
          <td key={"quantity" + ingredient.name}>{ingredient.quantity}</td>
          <td key={"ingredient" + ingredient.name}>{ingredient.unit}</td>
          <td key={"name" + ingredient.name}>{ingredient.name}</td>
        </tr>
      );
    });
  };
  setIngredient = (index: number, ingredient: Ingredient) => {
    const ingredients = this.state.recipe.ingredients.slice();
    ingredients[index] = ingredient;
    const recipe = this.state.recipe;
    this.setState({
      ...this.state,
      recipe: {
        id: recipe.id,
        author: recipe.author,
        name: recipe.name,
        instructions: recipe.instructions,
        course: recipe.course,
        ingredients: ingredients,
        tags: recipe.tags,
      },
    });
  };
  setName = (event: any) => {
    const recipe = this.state.recipe;
    this.setState({
      ...this.state,
      recipe: {
        id: recipe.id,
        author: recipe.author,
        instructions: recipe.instructions,
        course: recipe.course,
        name: event.target.value,
        ingredients: recipe.ingredients,
        tags: recipe.tags
      },
    });
  };
  setInstructions = (event: any) => {
    const recipe = this.state.recipe;
    this.setState({
      ...this.state,
      recipe: {
        id: recipe.id,
        author: recipe.author,
        name: recipe.name,
        ingredients: recipe.ingredients,
        course: recipe.course,
        instructions: event.target.value,
        tags: recipe.tags
      },
    });
  };
  changeCourse = (event: any) => {
    const recipe = this.state.recipe;
    this.setState({
      ...this.state,
      recipe: {
        id: recipe.id,
        author: recipe.author,
        name: recipe.name,
        instructions: recipe.instructions,
        ingredients: recipe.ingredients,
        course: event.target.value,
        tags: recipe.tags
      },
    });
  };

  moveIngredient = (fromIndex: number, toIndex: number) => {
    const ingredients = this.state.recipe.ingredients.slice();
    const recipe = this.state.recipe;
    if (toIndex >= ingredients.length || toIndex < 0) return;
    var element = ingredients[fromIndex];
    ingredients.splice(fromIndex, 1);
    ingredients.splice(toIndex, 0, element);
    this.setState({
      ...this.state,
      recipe: {
        id: recipe.id,
        author: recipe.author,
        name: recipe.name,
        instructions: recipe.instructions,
        course: recipe.course,
        ingredients: ingredients,
        tags: recipe.tags,
      },
    });
  };

  deleteIngredient = (index: number) => {
    const recipe = this.state.recipe;
    const ingredients = this.state.recipe.ingredients.slice();
    ingredients.splice(index, 1);
    this.setState({
      ...this.state,
      recipe: {
        id: recipe.id,
        author: recipe.author,
        name: recipe.name,
        instructions: recipe.instructions,
        course: recipe.course,
        ingredients: ingredients,
        tags: recipe.tags,
      },
    });
  };

  renderIngredientsEditor = (ingredients: Ingredient[]) => {
    return ingredients.map((ingredient, index) => {
      return (
        <tr key={"row" + index}>
          <td key={"quantity" + index}>
            <Form.Control
              key={"quantity" + index}
              type="text"
              name="quantity"
              onChange={(e: any) =>
                this.setIngredient(
                  index,
                  new Ingredient(
                    ingredient.name,
                    e.target.value,
                    ingredient.unit
                  )
                )
              }
              value={"" + ingredient.quantity}
            />
          </td>
          <td key={"unit" + index}>
            <Form.Control
              key={"unit" + index}
              type="text"
              name="unit"
              onChange={(e: any) =>
                this.setIngredient(
                  index,
                  new Ingredient(
                    ingredient.name,
                    ingredient.quantity,
                    e.target.value
                  )
                )
              }
              value={ingredient.unit}
            />
          </td>
          <td key={"name" + index}>
            <Form.Control
              key={"name" + index}
              type="text"
              name="name"
              onChange={(e: any) =>
                this.setIngredient(
                  index,
                  new Ingredient(
                    e.target.value,
                    ingredient.quantity,
                    ingredient.unit
                  )
                )
              }
              value={ingredient.name}
            />
          </td>
          <td key={"controls" + index}>
            <ButtonGroup>
              <Button
                onClick={() => this.moveIngredient(index, index - 1)}
                disabled={index === 0}
                key={"controlUp" + index}
                variant="outline-secondary"
                className="control-btn"
              >
                &uarr;
              </Button>
              <Button
                onClick={() => this.moveIngredient(index, index + 1)}
                disabled={index === ingredients.length - 1}
                key={"controlDown" + index}
                variant="outline-secondary"
                className="control-btn"
              >
                &darr;
              </Button>
            </ButtonGroup>
          </td>
          <td key={"delete" + index}>
            <ButtonGroup>
              <Button
                onClick={() => this.deleteIngredient(index)}
                key={"delete" + index}
                variant="outline-danger"
                className="control-btn"
              >
                X
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    });
  };
  render() {
    let { recipe } = this.state;

    if (!recipe || recipe.id !== this.props.recipe.id) {
      this.setState({ ...this.state, recipe: this.props.recipe });
      return null;
    }
    const renderIngredients = this.renderIngredientsEditor(recipe.ingredients);
    return (
      <Col>
        <Row>
          <Form.Group>
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="text"
              value={recipe.name}
              name="name"
              onChange={this.setName}
            />
          </Form.Group>
        </Row>
        <Row>
          <p>von {recipe.author}</p>
        </Row>
        <Row>
          <Form.Group style={{ width: "100%" }}>
            <Button
              style={{ float: "right" }}
              variant="success"
              onClick={() => {
                this.setIngredient(
                  recipe.ingredients.length,
                  new Ingredient("", 1, "")
                );
              }}
            >
              Zutat +
            </Button>
          </Form.Group>
        </Row>
        <Row>
          <Table striped bordered size="sm">
            <thead>
              <tr>
                <th>Menge</th>
                <th>Einheit</th>
                <th>Zutat</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderIngredients}</tbody>
          </Table>
        </Row>
        <Row>
          <Form.Group>
            <Form.Label>Gang:&nbsp;&nbsp;&nbsp;</Form.Label>
            <Form.Check
              inline
              name="radio-course"
              label="Vorspeise"
              type="radio"
              onChange={this.changeCourse}
              checked={CourseEnum.APPETIZER === recipe.course}
              value={CourseEnum.APPETIZER}
              id={"radio-course-" + CourseEnum.APPETIZER}
            />
            <Form.Check
              inline
              name="radio-course"
              label="Hauptgericht"
              type="radio"
              onChange={this.changeCourse}
              checked={CourseEnum.MAIN === recipe.course}
              value={CourseEnum.MAIN}
              id={"radio-course-" + CourseEnum.MAIN}
            />
            <Form.Check
              inline
              name="radio-course"
              label="Nachspeise"
              type="radio"
              onChange={this.changeCourse}
              checked={CourseEnum.DESSERT === recipe.course}
              value={CourseEnum.DESSERT}
              id={"radio-course-" + CourseEnum.DESSERT}
            />
            <Form.Check
              inline
              name="radio-course"
              label="Gebäck"
              type="radio"
              onChange={this.changeCourse}
              checked={CourseEnum.PASTRY === recipe.course}
              value={CourseEnum.PASTRY}
              id={"radio-course-" + CourseEnum.PASTRY}
            />
          </Form.Group>
        </Row>
        <Row style={{ display: "block" }}>
          <CreatableSelect
            styles={{
              container: () => ({ width: "100%" }),
              menu: (base) => ({ ...base, position: "relative" }),
            }}
            isMulti
            onChange={this.handleTagging}
            options={this.props.options}
            defaultValue={recipe?.tags?.map((t) => ({ value: t, label: t }))}
            placeholder="Tags"
          />
        </Row>
        <Row>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label>Beschreibung:</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              value={recipe.instructions}
              name="instructions"
              onChange={this.setInstructions}
              rows="10"
            />
          </Form.Group>
        </Row>
        <Row>
          <Button
            variant="success"
            onClick={() => {
              this.props.save(
                new Recipe({
                  id: recipe.id,
                  name: recipe.name,
                  instructions: recipe.instructions,
                  ingredients: recipe.ingredients,
                  author: this.props.user,
                  course: recipe.course,
                  tags: recipe.tags,
                })
              );
            }}
          >
            Speichern
          </Button>
        </Row>
      </Col>
    );
  }
}
