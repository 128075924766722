import { Ingredient } from "./Ingredient";

export enum CourseEnum {
  MAIN = "MAIN",
  APPETIZER = "APPETIZER",
  DESSERT = "DESSERT",
  PASTRY = "PASTRY",
}
export class Recipe {
  public id: string;
  public name: string;
  public instructions: string;
  public ingredients: Ingredient[];
  public author?: string = "";
  public course?: CourseEnum = CourseEnum.MAIN;
  public tags?: string[] = [];
  constructor(recipe: Recipe) {
    Object.assign(this, recipe);
  }
}
